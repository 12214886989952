import type { FunctionalComponent } from 'preact';

import { useCohortDate } from 'src/shared/hooks/use-cohort-date/use-cohort-date.js';
import type { CountryType } from 'src/shared/types/countries.js';

import type { BlockProfessions1ItemProps } from './block-professions-1-item.js';
import { BlockProfessions1Item } from './block-professions-1-item.js';

export type BlockProfessions1ItemContainerProps = Omit<BlockProfessions1ItemProps, 'cohortDate'> & {
    countrySlug: CountryType;
    professionSlug: string;
};

export const BlockProfessions1ItemContainer: FunctionalComponent<BlockProfessions1ItemContainerProps> = ({
    professionSlug,
    countrySlug,
    ...props
}) => {
    const { cohortDate } = useCohortDate(countrySlug, professionSlug);

    return <BlockProfessions1Item {...props} cohortDate={cohortDate} />;
};
